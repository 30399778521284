<template>
    <div class="data-content-data" style="width: calc(100vw - 210px);">
        <div class="btn-add-entry" @click="createEntry">New Entry</div>
        <div class="btn-add-entry" style="margin-left: 20px;" @click="gotoDetail()">Entry Guidelines</div>

        <el-table :data="dataList" style="margin-top: 20px;font-size: 12px;width: 100vw;" empty-text="No Data"
            :highlight-current-row="false" :highlight-selection-row="false" :header-cell-class-name="'custom-head-cell'"
            :cell-style="tableCellstyle" :row-class-name="'custom-row-bg'">
            <el-table-column prop="entry.project_code" label="Program" width="100px"></el-table-column>
            <el-table-column prop="entry.category" label="Category" width="120px"></el-table-column>
            <el-table-column prop="entry.subcategory" label="Subcategory" width="220px"></el-table-column>
            <el-table-column prop="entry.title" width="220px" label="Entry Title"></el-table-column>
            <el-table-column prop="" label="Entry Status" width="130px">
                <template slot-scope="scope">
                    {{ formatShowEntryStatus(scope.row) }}
                </template>
            </el-table-column>
            <el-table-column prop="" label="Entry Type" width="90px">
                <template slot-scope="scope">
                    {{ scope.row.entry.entry_type == 'team' ? 'Team' : 'Individual' }}
                </template>
            </el-table-column>
            <el-table-column prop="" label="Operation" width="300px">
                <template slot-scope="scope">
                    <div
                        v-if="!checkPay(scope.row) && checkEntryStatus(scope.row.entry, scope.row.project_manage) == ''">
                        <el-button type="primary" size="small"
                            @click="gotoTranscriptView(scope.row._id.$id)">Transcript</el-button>
                        <el-button type="primary" size="small" v-if="(checkShowCert(scope.row))"
                            @click="gotoCertView(scope.row._id.$id)">Certificate</el-button>
                    </div>
                    <el-label
                        v-if="!checkPay(scope.row) && checkEntryStatus(scope.row.entry, scope.row.project_manage) != ''">Check
                        here on September 10th for
                        the results.</el-label>
                    <el-button type="success" size="small" v-if="checkPay(scope.row) && !checkPassDDL(scope.row)"
                        style="background-color: #1B3571;border-width: 0px;" @click="gotoPay(scope.row)">Pay</el-button>
                    <el-button type="info" size="small" v-if="checkEnaleShowEdit(scope.row) && !checkPassDDL(scope.row)"
                        style="background-color: #9E9E9E;border-width: 0px;"
                        @click="editEntry(scope.row)">Edit</el-button>
                    <el-button type="success" size="small"
                        v-if="checkEnaleShowEdit(scope.row) && !checkPassDDL(scope.row) && scope.row.entry.entry_type == 'team'"
                        @click="doShowMembersDialog(scope.row)"
                        style="background-color: #EDB84C;border-width: 0px;">Members</el-button>
                    <el-button v-if="checkPay(scope.row)" type="danger" size="small"
                        style="background-color: #C65149;border-width: 0px;"
                        @click="doRemove(scope.row)">Delete</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :visible.sync="showCreateEntryDialog" width="35%">
            <div style="margin-top: -30px;width: 100%;padding: 0px 10px;">
                <p style="text-align: center;color: var(--c-primary);font-size: 28px;font-weight: bold;">Create New
                    Entry</p>
                <el-form :model="entryInfo" :rules="rules" ref="entryForm" label-position="right" label-width="140px"
                    style="margin-top: 20px;">
                    <!-- <el-form-item label="Entry Title" prop="title">
                        <el-input v-model="entryInfo.title" style="width: 80%;" @input="handleTitleInputChanged"
                            placeholder="Maximum 50 characters"></el-input>
                    </el-form-item> -->
                    <el-form-item label="Category" prop="category">
                        <el-select v-model="entryInfo.category" placeholder="Please select" style="width: 85%;"
                            @change="handleCategoryChanged">
                            <el-option v-for="(category, index) in categoryList" :key="index" :value="category.name"
                                :label="category.name"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Subcategory" prop="subcategory">
                        <el-select v-model="entryInfo.subcategory" placeholder="Please select" style="width: 85%;">
                            <el-option v-for="(category, index) in subcategoryList" :key="index" :value="category"
                                :label="category"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Entry Type" prop="entry_type">
                        <el-radio v-model="entryInfo.entry_type" label="individual">Individual</el-radio>
                        <el-radio v-model="entryInfo.entry_type" label="team">Team</el-radio>
                    </el-form-item>
                    <!-- <el-form-item label="Tags" prop="tags">
                        <vue-tags-input style="font-size: 16px;" v-model="tag" :tags="tags" :max-tags="5"
                            :placeholder="tags.length > 0 ? '' : tagPlaceHolder"
                            @tags-changed="newTags => tags = newTags" />
                    </el-form-item> -->
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="saveNewEntry">Submit</el-button>
            </span>
        </el-dialog>
        <el-dialog :visible.sync="showMembersDialog" width="50%" :before-close="beforeMemberDialogClose">
            <div
                style="margin-top: -30px;width: 100%;padding: 0px 15px;box-sizing: border-box;max-height: 600px;overflow-y: auto;">
                <p style="text-align: center;color: var(--c-primary);font-size: 28px;font-weight: bold;">Team Members
                </p>
                <p style="color: #333;margin-top: 20px;">Add a member</p>
                <div style="display: flex;align-items: center;margin-top: 15px;justify-content: space-between;">
                    <el-input v-model="member_email" placeholder="Please input"></el-input>
                    <el-button type="success" size="small"
                        style="width: 60px;margin-left: 20px;background-color: var(--c-primary);border-width: 0px;"
                        @click="confirmAddMember">Add</el-button>
                </div>
                <el-table :data="selectEntryInfo.members" style="margin-top: 20px" empty-text="No Data">
                    <el-table-column prop="student_email" label="Email" width="250px"></el-table-column>
                    <el-table-column prop="" label="Name">
                        <template slot-scope="scope">
                            {{ scope.row.student ? scope.row.student.student_givenName_pinyin + ' ' +
                                scope.row.student.student_lastName_pinyin : '' }}
                        </template>
                    </el-table-column>
                    <el-table-column prop="" label="Status/Operation" width="180px">
                        <template slot-scope="scope">
                            <p v-if="(checkPay(scope.row)) == false"
                                style="color: var(--c-primary);font-size: 16px;font-family: GravityBold;">Paid</p>
                            <el-button v-else type="danger" size="small"
                                @click="doRemoveMember(scope.row._id.$id)">Delete</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-dialog>
    </div>
</template>
<script>
import {
    removeEntryMember,
    addEntryMember,
    removeEntry,
    getStudentEntryList,
    updateEntry
} from '../../api/index'
import VueTagsInput from "@johmun/vue-tags-input";
import {
    getUserId
} from '../../utils/store'
export default ({
    name: 'index',
    components: {
        VueTagsInput
    },
    data() {
        return {

            subcategoryList: [],
            categoryList: [
                {
                    name: 'Print Media',
                    subcategoryList: [
                        'Campus Magazines & Publications',
                        'Campus Newspapers',
                        'Yearbooks'
                    ]
                },
                {
                    name: 'Social Media',
                    subcategoryList: [
                        'Official SNS Accounts',
                        'Video Accounts'
                    ]
                },
                {
                    name: 'Multimedia',
                    subcategoryList: [
                        'Videos',
                        'Campus Short Films',
                        'Photographs',
                        'Event Promotions',
                        'Music Videos',
                    ]
                },
                {
                    name: 'Arts',
                    subcategoryList: [
                        'Comic Works',
                        'Design Works',
                        'Handicrafts',
                        'Sculptures',
                        'Paintings',
                        'Picture Books',
                        'Caligraphy'
                    ]
                }
            ],

            dataList: [],
            tagPlaceHolder: 'Maximum 5 tags, click "enter" for each',
            tag: '',
            tags: [],
            entryInfo: {
                student_id: getUserId(),
                id: '',
                title: '',
                entry_type: '',
                category: '',
                subcategory: '',
                tags: ''
            },
            rules: {
                title: [
                    { required: true, message: 'Please input', trigger: 'blur' }
                ],
                entry_type: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
                subcategory: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
                category: [
                    { required: true, message: 'Please select', trigger: 'blur' }
                ],
            },
            showCreateEntryDialog: false,
            showMembersDialog: false,
            member_email: '',
            selectEntryInfo: {},
            currentTime: new Date().getTime()
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {

        gotoTranscriptView(id) {
            this.$router.push({
                path: '/transcript',
                query: {
                    id: id
                }
            })
        },
        gotoCertView(id) {
            this.$router.push({
                path: '/cert',
                query: {
                    id: id
                }
            })
        },
        // formatCertPublishTime(row) {
        //     let announce_date = 0
        //     let entry_date = row.project_manage.entry_date
        //     for (let i = 0; i < entry_date.length; i++) {
        //         let item = entry_date[i]
        //         if (item.app_type == 'indie' && row.entry_type == 'individual') {
        //             announce_date = item.announce_date
        //             break
        //         }
        //         if (item.app_type == 'team' && row.entry_type == 'team') {
        //             announce_date = item.announce_date
        //             break
        //         }
        //     }
        // },

        formatShowEntryStatus(row) {
            let current_time = new Date().getTime()
            let ddl_date = 0
            let announce_date = 0
            let entry_date = row.project_manage.entry_date
            for (let i = 0; i < entry_date.length; i++) {
                let item = entry_date[i]
                if (item.app_type == 'indie' && row.entry_type == 'individual') {
                    ddl_date = item.ddl_date
                    announce_date = item.announce_date
                    break
                }
                if (item.app_type == 'team' && row.entry_type == 'team') {
                    ddl_date = item.ddl_date
                    announce_date = item.announce_date
                    break
                }
            }
            if (this.checkPay(row)) { //未支付情况

                if (current_time >= ddl_date) {
                    return 'Invalid'
                }
                return 'Pending Payment'
            }
            //已经支付
            if (current_time >= announce_date) {
                //展示证书
                return this.fromatAward(row)
            }
            if (current_time >= ddl_date) {
                return 'Under Review'
            }
            return 'Ongoing'
        },

        fromatAward(row) {
            let showAwardName = 'Not Awarded'
            if (row.eae_entry.score) {
                let entryScore = 0;
                let bonus_points = row.eae_entry.score.bonus_points;
                let scoring_dimension = row.eae_entry.score.scoring_dimension;
                let keys = Object.keys(scoring_dimension);
                for (let i = 0; i < keys.length; i++) {
                    let score = scoring_dimension[keys[i]];
                    entryScore = entryScore + parseInt(score);
                }
                let totalScore = entryScore + bonus_points;
                if (totalScore >= 90) {
                    showAwardName = "Platinum Award";
                } else if (totalScore >= 80) {
                    showAwardName = "Gold Award";
                } else if (totalScore >= 65) {
                    showAwardName = "Silver Award";
                } else if (totalScore >= 50) {
                    showAwardName = "Bronze Award";
                }
            }
            return showAwardName
        },

        checkShowCert(entry) {
            if (entry.eae_entry.score) {
                let entryScore = 0;
                let bonus_points = entry.eae_entry.score.bonus_points;
                let scoring_dimension = entry.eae_entry.score.scoring_dimension;
                let keys = Object.keys(scoring_dimension);
                for (let i = 0; i < keys.length; i++) {
                    let score = scoring_dimension[keys[i]];
                    entryScore = entryScore + parseInt(score);
                }
                let totalScore = entryScore + bonus_points;
                if (totalScore >= 50) {
                    return true
                }
            }
            return false
        },


        beforeMemberDialogClose() {
            this.fetchData()
            this.showMembersDialog = false
        },
        //显示证书按钮

        checkEntryStatus(entry, project_manage) {
            let current_time = new Date().getTime()
            let ddl_date = 0
            let announce_date = 0
            let entry_date = project_manage.entry_date
            for (let i = 0; i < entry_date.length; i++) {
                let item = entry_date[i]
                if (item.app_type == 'indie' && entry.entry_type == 'individual') {
                    ddl_date = item.ddl_date
                    announce_date = item.announce_date
                    break
                }
                if (item.app_type == 'team' && entry.entry_type == 'team') {
                    ddl_date = item.ddl_date
                    announce_date = item.announce_date
                    break
                }
            }
            // if (false) {
            //     return ''
            // }
            if (current_time >= announce_date) {
                return ''
            }
            if (current_time >= ddl_date) {
                return 'Under Review'
            }
            return 'Ongoing'
        },

        checkPay(row) {
            if (row.application) {
                let status = row.application.application_status
                if (status == '无需缴费' || status == '已缴费') {
                    return false;
                }
            }
            return true
        },
        checkPassDDL(row) {
            if (row.project_ddl + 86400000 <= this.currentTime) {
                return true;
            }

            return false;
        },

        checkEnaleShowEdit(row) {
            if (row.application) {
                let status = row.application.application_status
                if (status == '无需缴费' || status == '已缴费') {
                    return true;
                }
            }
            return false
        },

        tableCellstyle() {
            return 'background:  #FAE3E4;border:1px solid white;text-align:center; font-family: GravityBold;'
        },
        doRemoveMember(id) {
            removeEntryMember(id).then((res) => {
                this.$message.success('Team member removed successfully')
                this.showMembersDialog = false
                this.fetchData()
            })
        },

        confirmAddMember() {
            if (this.member_email == '') {
                this.$message.warning('Please input')
                return
            }
            addEntryMember(this.selectEntryInfo.entry_id, this.member_email, 'member').then((res) => {
                let result = res.data
                if (result.code == 0) {
                    this.selectEntryInfo.members.push(result.data)
                    this.$message.success('Team member added successfully')
                } else {
                    this.$message.error(result.msg)
                }
            })
        },

        doShowMembersDialog(row) {
            this.member_email = ''
            this.selectEntryInfo = row
            this.showMembersDialog = true
        },

        editEntry(row) {
            this.$router.push({
                path: '/home/entry',
                query: {
                    entry_id: row.entry._id.$id
                }
            })
        },

        gotoPay(row) {
            this.$router.push({
                path: '/home/application',
                query: {
                    id: row.application._id.$id
                }
            })
        },

        doRemove(row) {
            this.$confirm(
                "Are you sure you want to delete this entry?", "Delete Entry", { type: "warning", cancelButtonText: 'Cancel', confirmButtonText: "Confirm", })
                .then(() => {
                    removeEntry(row._id.$id).then((res) => {
                        this.$message.success("Entry removed successfully");
                        this.fetchData()
                    })
                })
        },

        createEntry() {
            this.tag = ''
            this.tags = []
            this.subcategoryList = []
            this.entryInfo = {
                student_id: getUserId(),
                id: '',
                title: '',
                entry_type: '',
                category: '',
                subcategory: '',
                tags: ''
            }
            this.showCreateEntryDialog = true
        },

        handleCategoryChanged() {
            this.entryInfo.subcategory = ''
            for (let i = 0; i < this.categoryList.length; i++) {
                if (this.entryInfo.category == this.categoryList[i].name) {
                    this.subcategoryList = this.categoryList[i].subcategoryList
                }
            }
        },

        fetchData() {
            getStudentEntryList(getUserId()).then((res) => {
                this.dataList = res.data.data
            })
        },
        handleTitleInputChanged(value) {
            if (value) {
                this.entryInfo.title = value.charAt(0).toUpperCase() + value.slice(1);
            }
        },
        saveNewEntry() {
            let titleLength = this.entryInfo.title.length;
            if (titleLength > 50) {
                this.$message.error("Entry title characters at maximum 50");
                return;
            }
            if (this.entryInfo.tags) {
                let temp_tags = this.entryInfo.tags.split(',')
                if (temp_tags.length > 5) {
                    this.$message.error('Entry tags at maximum  5 tags')
                    return
                }
            }
            this.entryInfo.tags = ''
            if (this.tags.length > 0) {
                let length = this.tags.length
                for (var i = 0; i < length; i++) {
                    var tag = this.tags[i].text
                    this.entryInfo.tags = this.entryInfo.tags + tag
                    if (i != (length - 1)) {
                        this.entryInfo.tags = this.entryInfo.tags + ","
                    }
                }
            }
            this.$refs.entryForm.validate((val) => {
                if (val) {
                    updateEntry(this.entryInfo).then((res) => {
                        let result = res.data
                        if (result.code == 0) {
                            this.showCreateEntryDialog = false
                            this.$message.success("New entry created successfully");
                            this.fetchData()
                        } else {
                            this.$message.error(result.msg);
                        }
                    })
                }
            })
        },
        gotoDetail() {
            window.open('https://ap.learningfirst.tech/WSPR/WSPR%20Entry%20Preparation%20Guidelines.pdf', '_blank')
        }
    }
})
</script>


<style scoped lang="scss">
/deep/ .el-table .custom-row-bg {
    background: #FAE3E4;
}

/deep/ .el-table .custom-head-cell {
    background: var(--c-primary);
    color: white;
    text-align: center;
    border: 1px solid white;
    box-sizing: border-box;
    font-family: GravityBold;
}

.btn-add-entry {
    padding: 10px 20px;
    display: inline-block;
    text-align: center;
    background: var(--c-primary);
    border-radius: 5px;
    color: white;
    font-weight: bold;
    font-size: 15px;
    cursor: pointer;
}
</style>